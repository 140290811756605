import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h2>Privacy Policy</h2>
      <p>
        At <strong>Safe Appetite</strong>, we value your privacy and are committed to protecting your personal information.
        This Privacy Policy outlines how we collect, use, and protect the information you provide when using our app.
      </p>
      
      <h3>1. Information We Collect</h3>
      <p>We may collect the following types of information when you use Safe Appetite:</p>
      <ul>
        <li>Personal details such as your name, email address, and preferences.</li>
        <li>Data about the products you scan, your dietary preferences, intolerances, and dislikes.</li>
        <li>Usage data, including how you interact with the app, error logs, and performance information.</li>
      </ul>
      
      <h3>2. How We Use Your Information</h3>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li>To personalize the app based on your preferences, intolerances, and dislikes.</li>
        <li>To improve the functionality and performance of the app.</li>
        <li>To communicate with you regarding app updates, services, and offers (with your consent).</li>
      </ul>
      
      <h3>3. Sharing Your Information</h3>
      <p>We do not share your personal information with third parties, except in the following cases:</p>
      <ul>
        <li>With your explicit consent.</li>
        <li>If required by law, to comply with legal processes or protect our legal rights.</li>
      </ul>
      
      <h3>4. Data Security</h3>
      <p>We take appropriate security measures to protect your personal data against unauthorized access, disclosure, or destruction.
         However, please be aware that no system is completely secure.</p>
      
      <h3>5. Your Rights</h3>
      <p>You have the right to:</p>
      <ul>
        <li>Access, update, or delete your personal information.</li>
        <li>Request a copy of your data.</li>
        <li>Withdraw your consent to data processing at any time.</li>
      </ul>
      
      <h3>6. Updates to This Policy</h3>
      <p>We may update this Privacy Policy from time to time. Any changes will be reflected in the app, and you will be notified of significant changes.</p>
      
      <h3>7. Contact Us</h3>
      <p>If you have any questions or concerns about our Privacy Policy, please contact us at:</p>
      <p>Email: <a href="mailto:info@safeappetite.com">info@safeappetite.com</a></p>
    </div>
  );
}

export default PrivacyPolicy;
