import React from 'react';
import './Home.css';  // Styling for home page

function Home() {
  return (
    <div className="home">
      <div className="logo-container">
        <img src="/logo.png" alt="Logo" className="home-logo" />
        <h1>Coming Soon</h1>
      </div>
    </div>
  );
}

export default Home;
